import React from 'react';
import { Card, Button } from '@mui/material';

import './style.css';

export const BuyFourCoursesBanner = () => {
	return (
		<div className='buy-four-course-banner__wrapper'>
			<Card className='buy-four-course-banner'>
				<div className='buy-four-course-banner__text-container'>
					<div className='course-banner__button-block'>
						<div className='buy-four-course-banner__title-container'>
							<span>
								готовься с нами ко всем экзаменам -
							</span>
							<span>
								<b>это выгодно!</b>
							</span>
						</div>
						<div className='buy-four-course-banner__button'>
							<Button
								variant='contained'
								color='primary'
								href='https://portal.lomonosov.school/shop'
								target='_blank'
							>
								Выбрать курсы
							</Button>
						</div>
					</div>
					<span className='buy-four-course-banner__description'>
						собери уникальное комбо из нескольких предметов,<br />
						чтобы получить максимальное преимущество.
					</span>
					<span className='buy-four-course-banner__description'>
						каждый 4-ый предмет в подарок, а 2-ой и 3-ий с<br />
						большой скидкой!
					</span>
				</div>
				<img
					className='buy-four-course-banner__faces'
					src='https://unilogia-frontend.storage.yandexcloud.net/couse_banner_faces_f28a5a27be.png'
					alt='banner'
				/>
			</Card>
		</div>
	);
};
