import { makeAutoObservable, runInAction } from 'mobx';

import { UserCourseService } from '@services';
import { userStore } from './user-store';
import { ICurrentLessonProps } from '../../services/user-services/user-course-service';
import { ICourseListItem, ICurrentCourseProps } from '@interfaces';
import { IUserSectionsList } from '@interfaces/IUserLesson';

class UserCourseStore {
	courses: ICourseListItem[] = [];
	lessons: IUserSectionsList = {
		past: [],
		current: [],
		future: []
	};

	filteredCourses: ICourseListItem[] = [];

	currentCourse: ICurrentCourseProps | null = null;
	currentSection: OptionSelect | null = null;
	currentLesson: ICurrentLessonProps;
	currentTeacher: any;

	timeline: keyof IUserSectionsList = 'current';
	selectedCourse: OptionSelect = defaultSelectedCourse;
	disciplines: any = [];

	courseBlockedModalState: boolean = false;

	loading: Record<string, boolean> = {
		isLoading: false,
		isLoadingCourse: true,
		isLoadingLessons: true,
		isLoadingLesson: false
	};

	isLoadingCourse: boolean = false;
	isLoadingLessons: boolean = false;
	isLoadingLesson: boolean = false;

	isShowStudentsRankingModal: boolean = false;

	constructor () {
		makeAutoObservable(this);
	}

	//  Получить все курсы
	async getCourses () {
		try {
			this.setIsLoading('isLoading', true);
			const { data } = await UserCourseService.getCourses(userStore.user.id);
			runInAction(() => {
				this.courses = this.filteredCourses = data;
				this.disciplines = data
					.map(course => ({ id: course.discipline.id, name: course.discipline.name }))
					.filter((item, index, arr) =>
						arr.findIndex(elem => elem.id === item.id) === index
					);
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.setIsLoading('isLoading', false);
		}
	}

	// Получить конкретный курс по ID
	async getCurrentCourse (courseId: string) {
		try {
			this.setIsLoading('isLoadingCourse', true);
			const { data } = await UserCourseService.getCurrentCourse(userStore.user.id, courseId);
			runInAction(() => {
				this.timeline = 'current';
				if (data.settings?.[0]?.healthCount <= 0) this.setCourseBlockedModalState();
				this.currentCourse = data;
				this.currentSection = data.sections[data.sections.length - 1];
				if (this.currentSection) {
					this.getLessons(data.sections[data.sections.length - 1].id);
				} else {
					this.loading.isLoadingLessons = false;
				}
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.setIsLoading('isLoadingCourse', false);
		}
	}

	async updateSettingCourse (courseId:string, settingId: string, settingCourse) {
		try {
			const { data } = await UserCourseService.updateSettingCourse(settingId, settingCourse);
			this.courses = this.filteredCourses = this.filteredCourses
				.map(course => course.id === courseId ? { ...course, setting: settingCourse } : course);
			this.currentCourse = { ...this.currentCourse, settings: [data] } as ICurrentCourseProps;
		} catch (e) {
			console.log(e);
		}
	}

	resetCourse () {
		this.currentCourse = {} as ICurrentCourseProps;
	}

	// Получение всех уроков конкретной секции
	async getLessons (sectionId: string) {
		try {
			this.setIsLoading('isLoadingLessons', true);
			const { data } = await UserCourseService.getLessons(userStore.user.id, sectionId);

			data.current = data.current.filter(dc => !dc.isWrongTariff);
			data.past = data.past.filter(dp => !dp.isWrongTariff);
			data.future = data.future.filter(df => !df.isWrongTariff);

			runInAction(() => {
				this.lessons = data;
				if (data.current?.length === 0) {
					this.changeTimeline('past');
				} else {
					this.changeTimeline('current');
				}
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.setIsLoading('isLoadingLessons', false);
		}
	}

	// Получение конкретного урока
	async getCurrentLesson (lessonId: string, errorAction?: () => void, source?: 'section' | 'gamification' | undefined) {
		try {
			this.setIsLoading('isLoadingLesson', true);
			const { data } = await UserCourseService.getCurrentLesson(userStore.user.id, lessonId, source);
			runInAction(() => {
				this.currentLesson = data;
			});

			if (data.eventId && data.videoType === 'kinescope') {
				const { data: kinescope } = await UserCourseService.getKinescopeEvent(data.eventId);
				this.currentLesson.source = kinescope;
			}
		} catch (e) {
			if (errorAction) {
				errorAction();
			}
		} finally {
			this.setIsLoading('isLoadingLesson', false);
		}
	}

	async deleteCurrentCourse (courseId: string) {
		try {
			this.setIsLoading('isLoading', true);
			await UserCourseService.deleteCourse(courseId);
			runInAction(() => {
				this.courses = this.filteredCourses = this.courses.filter(course => course.id !== courseId);
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.setIsLoading('isLoading', false);
		}
	}

	selectSection (section: OptionSelect) {
		this.currentSection = section;
		this.getLessons(section.id);
	}

	setIsLoading (route: string, state: boolean) {
		this.loading[route] = state;
	}

	setSelectedCourse (course: OptionSelect | null) {
		this.selectedCourse = course || defaultSelectedCourse;
		if (!course) {
			this.filteredCourses = this.courses;
		} else {
			this.filteredCourses = this.courses.filter((_course) => _course.discipline.id === course.id);
		}
	}

	setCurrentTeacher (teacher) {
		this.currentTeacher = teacher;
	}

	changeTimeline (newValue: keyof IUserSectionsList) {
		this.timeline = newValue;
	}

	changeStudentsRankingModal () {
		this.isShowStudentsRankingModal = !this.isShowStudentsRankingModal;
	}

	setCourseBlockedModalState (value: boolean | undefined = undefined) {
		this.courseBlockedModalState = value || !this.courseBlockedModalState;
	}

	changeLessonIsVisible (id: string) {
		this.lessons[this.timeline] = this.lessons[this.timeline].map(lesson => lesson.shortId === id ? { ...lesson, viewedUsers: [{ id }] } : lesson);
	}

	reset () {
		this.courses = [];
		this.lessons = {
			past: [],
			current: [],
			future: []
		};
		this.currentCourse = {} as ICurrentCourseProps;
		this.currentSection = null;
		this.timeline = 'current';
		this.selectedCourse = { id: 'all', name: 'Все предметы' };
		this.filteredCourses = this.courses;
		this.isShowStudentsRankingModal = false;
		this.courseBlockedModalState = false;
	}
}

const defaultSelectedCourse = { id: 'all', name: 'Все предметы' };

export const userCourseStore = new UserCourseStore();
