import { makeAutoObservable, reaction } from 'mobx';
import {
	DiscountPerCount,
	IGetShopButtonsItemResponse, IGetShopCategoryResponse,
	IOptionItem, IShopCourse,
	IShopTariffPlanPeriod, IShopTariffPlanTariff,
	PurchaseCourse, PurchasePaymentDto, PurchasePaymentResponse,
	ShopNavigationStep, IShopInvoice,
	TrialNavigationStep
} from '@interfaces';
import { ShopUserService } from '@services';
import { getPeriodNames } from '@helpers';
import {authStore, generalStore, userStore} from '@stores';
import { IPromocode } from '@features/activate-promocode';

type SelectedOptionsType = Partial<Record<ShopNavigationStep, IOptionItem | null>>;

type ModalNameType = 'selectCourses' | 'QA';

export interface ISelectedOptions extends Omit<SelectedOptionsType, ShopNavigationStep.COURSES | ShopNavigationStep.TARIFF | ShopNavigationStep.RESULT> {
	[ShopNavigationStep.COURSES]: Record<DiscountPerCount, IShopCourse | null>;
	[ShopNavigationStep.TARIFF]?: {
		tariff: IShopTariffPlanTariff | null;
		period: IShopTariffPlanPeriod | null;
	} | null;
	[ShopNavigationStep.RESULT]?: {
		sum: number;
		discount: number;
	} | null
}

class ShopStore {
	stepPage: ShopNavigationStep | TrialNavigationStep = ShopNavigationStep.DIRECTION;
	directions: IGetShopButtonsItemResponse[] = [];
	categories: IGetShopButtonsItemResponse[] = [];
	category: IGetShopCategoryResponse | null = null;
	isLoading: boolean = false;
	error: string | null = null;
	selectedOptions: ISelectedOptions = defaultSelectedOptions;
	orderTypeCourse: DiscountPerCount = DiscountPerCount.FIRST;
	payment: PurchasePaymentResponse | null = null;
	isCompletedPayment: boolean = false;
	intervalId: NodeJS.Timeout | null = null;
	modalName: ModalNameType | null = null;
	invoice: IShopInvoice | null = null;
	isFirstPurchase: boolean = false;
	activePromo: IPromocode = defaultPromo;

	constructor () {
		makeAutoObservable(this);
		reaction(
			() => this.payment?.OrderId,
			() => {
				if (!this.isCompletedPayment && this.payment?.OrderId && this.selectedOptions[ShopNavigationStep.RESULT]?.sum) {
					this.intervalId = setInterval(() => {
						this.checkStatusSbp(this.payment!.OrderId);
					}, 5000);
				} else {
					this.intervalId &&
          clearInterval(this.intervalId);
				}
			}
		);
	}

	changeOrderTypeCourse (type: DiscountPerCount) {
		this.orderTypeCourse = type;
	}

	changeStepPanel (step: ShopNavigationStep | number) {
		this.stepPage = step;
	}

	clearError () {
		this.error = null;
	}

	setSelectedOption (option: Partial<ISelectedOptions>) {
		this.selectedOptions = {
			...this.selectedOptions,
			...option
		};
	}

	clearShop () {
		this.payment = null;
		this.isCompletedPayment = false;
	}

	changeOptionCourse (course: IShopCourse | null) {
		this.selectedOptions[ShopNavigationStep.COURSES][this.orderTypeCourse] = course;
	}

	changeModal (type: ModalNameType | null) {
		this.modalName = type;
	}

	getBeautifulPrice (price: number): { total: number, remains: number } {
		const temp = price % 10;
		return {
			total: price - temp,
			remains: temp
		};
	}

	getSum (tariffPlanPeriodPrice?: number): { total: number, discount: number } {
		let total = 0;
		let discount = 0;

		const sumCourses = Object.entries(this.selectedOptions[ShopNavigationStep.COURSES]).reduce((sum, [key, item]) => {
			// Если период у курса не указан, то берем цену из тарифного плана
			const price = (item?.period?.price || tariffPlanPeriodPrice || this.selectedOptions[ShopNavigationStep.TARIFF]?.period?.price || 0);
			const discountPrice = price - (price * (this.category?.tariffPlan?.discountPerCount?.[key] || 0) / 100);
			if (item) {
				return {
					total: sum.total + discountPrice,
					discount: sum.discount + (price - discountPrice)
				};
			}
			return sum;
		}, { total: 0, discount: 0 });

		total += sumCourses.total;
		discount += sumCourses.discount;

		// Если это первая покупка и реферал, возвращаем доп скидку
		if (localStorage.getItem('ref') && (this.isFirstPurchase || !authStore.isAuth)) {
			const { discountPerUser, discountThreshold } = this.getDiscountReferral(this.selectedOptions[ShopNavigationStep.DIRECTION]?.name);
			if (sumCourses.total >= discountThreshold) {
				total = sumCourses.total - discountPerUser;
				discount = sumCourses.discount + discountPerUser;
			}
			console.log(discountPerUser, discountThreshold);
		}

		// Если есть промокод
		if (this.activePromo.promocode) {
			const { discount: discountWithPromocode, total: totalWithPromocode } = this.calculateSumWithPromocode(total, this.activePromo);
			total = totalWithPromocode;
			discount += discountWithPromocode;
		}

		// Если есть промокод
		if (this.activePromo.promocode) {
			const sum = this.getBeautifulPrice(sumCourses.total).total;
			const discount = sumCourses.discount + this.getBeautifulPrice(sumCourses.total).remains;
			const { discount: discountPromocode, total } = this.calculateSumWithPromocode(sum, this.activePromo);
			return {
				discount: discount + discountPromocode,
				total: total
			};
		}

		return {
			total: this.getBeautifulPrice(total).total,
			discount: discount + this.getBeautifulPrice(total).remains
		};
	}

	async getDirectionsButtons (trial?: boolean) {
		try {
			this.isLoading = true;
			const { data } = await ShopUserService.getButtons('null', trial);
			this.directions = data;
			if (data.length === 0) {
				this.error = 'Тут ничего нет';
			}
		} catch (error) {
			this.error = 'Упс... кажется сервер упал :(';
			console.log(error);
		} finally {
			this.isLoading = false;
		}
	}

	async getCategoriesButtons (directionId: string, trial?: boolean) {
		try {
			this.isLoading = true;
			const { data } = await ShopUserService.getButtons(directionId, trial);
			this.categories = data;
			if (data.length === 0) {
				this.error = 'Категории не найдены';
			}
		} catch (error) {
			this.error = 'Упс... кажется сервер упал :(';
			console.log(error);
		} finally {
			this.isLoading = false;
		}
	}

	async getCategoryCourses (categoryId: string) {
		try {
			this.isLoading = true;
			const { data } = await ShopUserService.getCourses(categoryId);
			this.category = data;
			this.selectedOptions[ShopNavigationStep.COURSES] = defaultSelectedOptions[ShopNavigationStep.COURSES];
			this.selectedOptions[ShopNavigationStep.TARIFF] = null;
			if (data.courses.length === 0) {
				this.error = 'Курсов нету';
			}
		} catch (error) {
			this.error = 'Упс... кажется сервер упал :(';
			console.log(error);
		} finally {
			this.isLoading = false;
		}
	}

	async getSbpQr (orderId: string): Promise<string | null> {
		try {
			this.isLoading = true;
			const { data } = await ShopUserService.createQR(orderId);
			if (!data.Success) generalStore.changeAlertState('warning', 'Не удалось сформировать счет');
			return data.Data || null;
		} catch (error) {
			console.log(error);
			this.isCompletedPayment = false;
			this.payment = null;
			return null;
		} finally {
			this.isLoading = false;
		}
	}

	async buyCourses (userId: string, cart: PurchasePaymentDto, shortId?: string) {
		if (!this.invoice?.userId && shortId) {
			await ShopUserService.putInvoiceByShortId(shortId, userId);
		} else if (this.invoice?.userId !== userId && shortId) {
			generalStore.changeAlertState('warning', 'Счет привязан к другому пользователю');
		}
		try {
			const { data } = await ShopUserService.createInvoice(userId, shortId, cart);
			if (!data.Success) generalStore.changeAlertState('warning', 'Не удалось сформировать счет');
			this.payment = data;
		} catch (error) {
			console.log(error);
			this.isCompletedPayment = false;
			this.payment = null;
		}
	}

	async activateTrialCourses (courses: PurchaseCourse[]): Promise<boolean> {
		try {
			const { data } = await ShopUserService.activateTrialCourses({ shopCourseData: courses });
			return !!data;
		} catch (error) {
			console.log(error);
			return false;
		}
	}

	async checkStatusSbp (orderId: string) {
		try {
			const { data } = await ShopUserService.checkQR(orderId);
			if (data) {
				this.isCompletedPayment = true;
			}
		} catch (error) {
			console.log(error);
		}
	}

	async getInvoiceByShortId (id: string) {
		try {
			const { data } = await ShopUserService.getInvoiceByShortId(id);
			this.invoice = data;
			const getCourse = (index: number): IShopCourse | null => {
				if (!data?.items?.[index]) return null;
				const period = {
					price: data.items[index]?.price,
					begins: data.items[index]?.begins,
					ends: data?.items[index]?.ends,
					id: data?.items[index]?.id,
					name: getPeriodNames(data?.items[index]?.begins, data?.items[index]?.ends)
				};
				return (
					{
						...data.items[index]?.tariff?.course,
						tariff: { ...data?.items[index]?.tariff, periods: [period] },
						tariffs: [{ ...data?.items[index]?.tariff, periods: [period] }],
						period: period
					}
				);
			};
			this.selectedOptions[ShopNavigationStep.COURSES] = {
				first: getCourse(0),
				second: getCourse(1),
				third: getCourse(2),
				fourth: getCourse(3)
			};
		} catch (error) {
			console.log(error);
		}
	}

	async restoreCart (selectedOptions: ISelectedOptions) {
		if (selectedOptions[ShopNavigationStep.RESULT]?.sum && selectedOptions[ShopNavigationStep.DIRECTION]?.id && selectedOptions[ShopNavigationStep.CATEGORY]?.id) {
			authStore.isAuth
				? this.stepPage = ShopNavigationStep.PAYMENT
				: this.stepPage = ShopNavigationStep.RESULT;
			await this.getCategoriesButtons(selectedOptions[ShopNavigationStep.DIRECTION].id, false);
			await this.getCategoryCourses(selectedOptions[ShopNavigationStep.CATEGORY].id);
		}
		this.selectedOptions = selectedOptions;

		localStorage.removeItem('shopCart');
	}

	async getIsFirstPurchase () {
		try {
			const { data } = await ShopUserService.getRefDiscount();
			this.isFirstPurchase = data;
		} catch (error) {
			console.log(error);
		}
	}

	calculateSumWithPromocode (sum: number, promocode: IPromocode | null): { total: number, discount: number } {
		if (promocode) {
			switch (promocode.typeDiscount) {
			case 'percent': {
				const discountAmount = sum * (promocode.discount / 100);
				return { total: sum - discountAmount, discount: discountAmount };
			}
			case 'rub': return { total: sum - promocode.discount, discount: promocode.discount };
			}
		}
		return { total: sum, discount: 0 };
	}

	// Функция для пересчета скидки за кол-во курсов (нигде не используется)
	getPriceWithCountDiscount (sum: number, countCourses: number) {
		// Определить процент скидки на основе количества курсов
		let discountPercentage = 0;
		switch (countCourses) {
		case 1:
			discountPercentage = 0;
			break;
		case 2:
			discountPercentage = 5;
			break;
		case 3:
			discountPercentage = 7.5;
			break;
		default:
			discountPercentage = 10;
		}

		// Вычислить сумму скидки
		const discountAmount = sum * (discountPercentage / 100);

		// Вычислить итоговую сумму с учетом скидки
		const totalWithDiscount = sum - discountAmount;

		// Вернуть объект с итоговой суммой и суммой скидки
		return {
			totalWithDiscount: this.getBeautifulPrice(totalWithDiscount).total,
			discountAmount: this.getBeautifulPrice(totalWithDiscount).remains + discountAmount
		};
	}

	getDiscountReferral (direction?: 'ЕГЭ' | 'ОГЭ' | string): { discountPerUser: number, discountThreshold: number} {
		switch (direction) {
		case 'ЕГЭ': return {
			discountPerUser: 500,
			discountThreshold: 3000
		};
		case 'ОГЭ': return {
			discountPerUser: 300,
			discountThreshold: 1990
		};
		default: return {
			discountPerUser: 0,
			discountThreshold: 0
		};
		}
	}

	async checkPromo (promo: string, periodID) {
		try {
			this.activePromo.isLoading = true;
			const { data } = await ShopUserService.postInfoPromoCode(promo, userStore.user.id, periodID);
			if (data) {
				this.activePromo = {
					promocode: data.info.promo,
					discount: data.status ? data.info.countDiscount : 0,
					typeDiscount: data.info.typeDiscount,
					error: data.status ? null : 'Промокод не действителен',
					isLoading: false
				};
			} else {
				this.activePromo = { ...defaultPromo, promocode: promo, error: 'Промокод не найден' };
			}
		} catch (error) {
			console.log(error);
			this.activePromo = { ...defaultPromo, error: 'Произошла ошибка' };
		} finally {
			this.activePromo.isLoading = false;
		}
	}
}

const defaultPromo: IPromocode = {
	promocode: '',
	discount: 0,
	typeDiscount: 'rub',
	isLoading: false,
	error: null
};

const defaultSelectedOptions: ISelectedOptions = {
	[ShopNavigationStep.COURSES]: {
		[DiscountPerCount.FIRST]: null,
		[DiscountPerCount.SECOND]: null,
		[DiscountPerCount.THIRD]: null,
		[DiscountPerCount.FOURTH]: null
	}
};

export const shopStore = new ShopStore();
